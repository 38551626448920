import styled from "styled-components";

export const Wrapper = styled.div`
	width: 100%;
	font-size: ${({ theme }) => theme.fontSize.m};
	padding: 5px;

	span {
		display: ${({ $isExtended }) => $isExtended ? "none" : "inline-block"};
		white-space: nowrap;
	}
`;

export const Controller = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: ${({ $isExtended }) => $isExtended ? "center" : "space-between"};
	gap: ${({ theme }) => theme.spacing.s};
	padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.m}`};

	svg {
		transform: ${({ $isExtended }) => $isExtended && "rotate(180deg)"};
		cursor: pointer;
		flex-shrink: 0;
	}

	button {
		display: ${({ $isExtended }) => $isExtended ? "none" : "inline-block"};
		cursor: pointer;
		outline: 0;
		font-weight: 400;
		line-height: 1.5;
		text-align: center;
		background-color: rgb(17, 18, 19);
		border: 1px solid transparent;
		padding: 3px 24px;
		font-size: 1rem;
		border-radius: .25rem;
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		color: #0d6efd;
		border-color: #0d6efd;
		:hover {
			color: #fff;
			background-color: #0d6efd;
			border-color: #0d6efd;
		}
	}
`;

export const ItemName = styled.div`
    display: flex;
	gap: 5px;
	align-items: center;

	cursor: pointer;
`;

export const Item = styled.div`
    display: flex;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.colors.menuItemSelect};
	color: ${({ theme }) => theme.colors.textSecondary};
	gap: ${({ theme }) => theme.spacing.m};
	padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
	border-radius: ${({ theme }) => theme.radius.xs};
	align-items: center;

	button {
		background: none repeat scroll 0 0 transparent;
        border: medium none;
        border-spacing: 0;
        color: #26589F;
        font-family: 'PT Sans Narrow',sans-serif;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.42rem;
        list-style: none outside none;
        margin: 0;
        padding: 0;
        text-align: left;
        text-decoration: none;
        text-indent: 0;
		font-size: ${({ theme }) => theme.fontSize.s};
		cursor: pointer;
	}

	svg {
		flex-shrink: 0;
	}

	svg path {
		fill: ${({ theme }) => theme.colors.textSecondary};
	}

	cursor: pointer;

`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xs};
	padding: 0;
	margin: 0;
	margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const SideBar = styled.div`
	position: relative;
	width: ${({ $isExtended, theme }) => $isExtended ? theme.width.extended : theme.width.full};
	transition: all 200ms;
	background-color: ${({ theme }) => theme.colors.secondary};
	color: ${({ theme }) => theme.colors.textSecondary};
`;

